import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldNumber, TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { categoryService, queryClient, testQuestionService } from 'services';
import { MyEditor } from 'views/Post/components';

type Props = PopupController & {
  question?: TestQuestion;
  groupId?: FormValue<number>;
  questionParentTopicId?: FormValue<number>;
  questionTopicId?: FormValue<number>;
};

const PopupQuestionUpsert = ({ question, groupId, questionParentTopicId, questionTopicId, onClose }: Props) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm<TestQuestionCreateBody>({
    defaultValues: {
      answers: (
        question?.answers ?? [
          { content: '', id: undefined, isCorrect: 0 },
          { content: '', id: undefined, isCorrect: 0 },
          { content: '', id: undefined, isCorrect: 0 },
          { content: '', id: undefined, isCorrect: 0 },
        ]
      ).map(({ id, content, isCorrect }) => ({
        id,
        content,
        isCorrect: isCorrect === 1,
      })),
      questionType: question?.questionType ?? 'CHOICE',
      groupId: question?.group?.id ?? groupId,
      parentTopicId: questionParentTopicId,
      topicId: questionTopicId,
    },
  });
  const {
    fields: answers,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'answers',
  });

  const questionType = watch('questionType');
  useEffect(() => {
    if (questionType !== 'CHOICE') {
      setValue('answers', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);

  const parentTopicId = watch('parentTopicId');
  const { data: dataParentTopic } = useQuery(['categoryService.getAllTestQuestionParentTopics'], () =>
    categoryService.getAllTestQuestionTopics({ parentId: '' }),
  );

  const { data: dataTopic } = useQuery(
    ['categoryService.getAllTestQuestionTopics', { parentId: parentTopicId }],
    () => categoryService.getAllTestQuestionTopics({ parentId: parentTopicId }),
    {
      enabled: !!parentTopicId,
    },
  );

  const { data: dataLevel } = useQuery(['categoryService.getAllQuestionLevels'], () =>
    categoryService.getAllQuestionLevels(),
  );

  const { mutate: addQuestion, isLoading: isLoadingAdd } = useMutation(testQuestionService.postTestQuestion, {
    onSuccess: (newQuestion: TestQuestion) => {
      enqueueSnackbar('Thêm mới câu hỏi thành công');
      queryClient.invalidateQueries(['testQuestionService.fetchTestQuestions']);
      reset({
        groupId: newQuestion.groupId,
        level: newQuestion.level,
        point: newQuestion.point,
        questionType: newQuestion.questionType,
        parentTopicId: newQuestion.parentTopicId,
        topicId: newQuestion.topicId,
        content: '',
        answers: [{ content: '' }, { content: '' }, { content: '' }, { content: '' }],
        helpContent: newQuestion.questionType === 'CHOICE' ? '<ul><li>Đáp án 1:</li><li>Đáp án 2:</li><li>Đáp án 3:</li><li>Đáp án 4:</li></ul>' : '',
      });
    },
  });

  const { mutate: updateQuestion, isLoading: isLoadingUpdate } = useMutation(testQuestionService.putTestQuestion, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật câu hỏi thành công');
      queryClient.invalidateQueries(['testQuestionService.fetchTestQuestions']);
      onClose();
    },
  });

  const handleClickSubmitAdd = () => {
    handleSubmit((values) => {
      addQuestion({
        ...values,
      });
    })();
  };

  const handleClickSubmitUpdate = () => {
    handleSubmit((values) => {
      updateQuestion({
        id: question!.id,
        ...values,
      });
    })();
  };

  return (
    <>
      {!question && <DialogTitle>Thêm mới câu hỏi</DialogTitle>}
      {question && <DialogTitle>Cập nhật câu hỏi</DialogTitle>}

      <DialogContent>
        <Container maxWidth='xl' className='mt-1 py-1'>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Controller
                name='parentTopicId'
                defaultValue={question?.parentTopicId ?? questionParentTopicId}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect {...field} fullWidth label='Chủ đề cha' error={!!error} helperText={error?.message}>
                    {(dataParentTopic?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name='topicId'
                defaultValue={question?.topicId ?? questionTopicId}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect {...field} fullWidth label='Chủ đề con' error={!!error} helperText={error?.message}>
                    {(dataTopic?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Controller
                name='questionType'
                defaultValue={question?.questionType}
                rules={{
                  required: 'Loại câu hỏi là bắt buộc',
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    fullWidth
                    required
                    label='Loại câu hỏi'
                    error={!!error}
                    helperText={error?.message}
                  >
                    <MenuItem key={1} value='CHOICE'>
                      Trắc nghiệm
                    </MenuItem>
                    <MenuItem key={2} value='ESSAY'>
                      Tự luận
                    </MenuItem>
                  </TextFieldSelect>
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Controller
                name='point'
                defaultValue={question?.point}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldNumber {...field} fullWidth label='Điểm' error={!!error} helperText={error?.message} />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Controller
                name='level'
                defaultValue={question?.level ?? ''}
                rules={{
                  required: 'Mức độ câu hỏi là bắt buộc',
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    fullWidth
                    required
                    label='Mức độ'
                    error={!!error}
                    helperText={error?.message}
                  >
                    {(dataLevel?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.code}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' color='info' className='mb-2 space-x-3'>
                <span>Nội dung câu hỏi</span>
              </Typography>
              <Controller
                name='content'
                defaultValue={question?.content}
                control={control}
                rules={{
                  required: 'Nội dung câu hỏi là bắt buộc',
                }}
                render={({ field, fieldState: { error } }) => (
                  <MyEditor onlyContent={true} data={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
          </Grid>
        </Container>
        {questionType === 'CHOICE' &&
          answers.map((_item, index) => {
            return (
              <Container key={index} maxWidth='xl' className='mt-4 py-1'>
                <Typography variant='h6' color='info' className='space-x-3'>
                  <span>Đáp án {index + 1}</span>
                  <Controller
                    name={`answers.${index}.isCorrect`}
                    defaultValue={_item.isCorrect}
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <FormControlLabel
                        key={1}
                        label='Đáp án đúng'
                        control={<Checkbox checked={field.value} {...field} color='primary' />}
                      />
                    )}
                  />
                  <LoadingButton
                    variant='outlined'
                    color='error'
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    Xóa
                  </LoadingButton>
                </Typography>
                <Grid container spacing={3} key={index} className='mt-1'>
                  <Grid item xs={12}>
                    <Controller
                      name={`answers.${index}.content`}
                      defaultValue={_item.content}
                      rules={{
                        required: 'Nội dung đáp án là bắt buộc',
                      }}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <MyEditor onlyContent={true} data={field.value} onChange={field.onChange} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Container>
            );
          })}
        {questionType === 'CHOICE' && (
          <Container maxWidth='xl' className='mt-2 py-1'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LoadingButton
                  variant='outlined'
                  onClick={() => {
                    append({ content: '', isCorrect: false });
                  }}
                >
                  Thêm đáp án
                </LoadingButton>
              </Grid>
            </Grid>
          </Container>
        )}
        <Container maxWidth='xl' className='mt-4 py-1'>
          <Grid item xs={12}>
            <Typography variant='h6' color='info' className='mb-2 space-x-3'>
              <span>Giải thích</span>
            </Typography>
            <Controller
              name='helpContent'
              defaultValue={
                question?.helpContent ??
                (questionType === 'CHOICE'
                  ? '<ul><li>Đáp án 1:</li><li>Đáp án 2:</li><li>Đáp án 3:</li><li>Đáp án 4:</li></ul>'
                  : '')
              }
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MyEditor onlyContent={true} data={field.value ?? ''} onChange={field.onChange} />
              )}
            />
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        {!question && (
          <LoadingButton variant='contained' loading={isLoadingAdd} onClick={handleClickSubmitAdd}>
            Thêm mới
          </LoadingButton>
        )}
        {question && (
          <LoadingButton variant='contained' loading={isLoadingUpdate} onClick={handleClickSubmitUpdate}>
            Cập nhật
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default PopupQuestionUpsert;
