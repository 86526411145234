import { Container, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

type Props = PopupController & {
  question: QuizQuestionDetail;
};

const PopupQuestionPreview = ({ question, onClose }: Props) => {
  return (
    <>
      <DialogTitle>Chi tiết câu hỏi</DialogTitle>

      <DialogContent>
        <Container maxWidth='xl' className='py-6'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h4' sx={{ whiteSpace: 'pre-wrap' }} className='space-x-3'>
                <span>{question.content}</span>
              </Typography>
            </Grid>
            {question.image && (
              <Grid item xs={12}>
                <img src={`${question.image}?w=164&h=164&fit=crop&auto=format`} loading='lazy' />
              </Grid>
            )}
          </Grid>
          <Divider light className='mb-2 mt-2' />
          {question.answers.map((answer, index) => (
            <div key={index}>
              <Grid container spacing={3}>
                <Grid item xs={12} className='mt-1'>
                  <Typography sx={{ fontSize: 18, whiteSpace: 'pre-wrap' }} className='space-x-3'>
                    <span>
                      {index + 1}. {answer.content}
                    </span>
                  </Typography>
                  {answer.isCorrect === 1 && <CheckCircle color='success' />}
                </Grid>
                {answer.image && (
                  <Grid item xs={12}>
                    <img src={`${answer.image}?w=164&h=164&fit=crop&auto=format`} loading='lazy' />
                  </Grid>
                )}
              </Grid>
            </div>
          ))}
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupQuestionPreview;
